import { WorkImageStatus } from '@common/enum';
import { CreateDraftWorkParams, CreateFinalWorkParams, DraftWorkQueryParams, DraftWorkRes, FinalWorkRes } from '@services/types/apiType';
import { AxiosInstance } from 'axios';

const draftWork = 'draft-work/';
const finalWork = 'final-work/';

export default class WorkService {
  constructor(private axios: AxiosInstance) {}

  getDraftWork = async ({ ...params }: DraftWorkQueryParams): Promise<DraftWorkRes> => {
    const { data } = await this.axios.get(`${draftWork}`, { params });
    return data;
  };

  getDraftWorkById = async (workId: string) => {
    const { data } = await this.axios.get(`${draftWork}/${workId}`);
    return data;
  };

  getFinalWorkById = async (workId: string) => {
    const { data } = await this.axios.get(`${finalWork}/${workId}`);
    return data;
  };

  deleteDraftImage = async ({ ...params }: { draftId: string; file: string }): Promise<null> => {
    const { data } = await this.axios.delete(`${draftWork}`, { params });
    return data;
  };

  createDraftWork = async ({ draftImages, ...params }: CreateDraftWorkParams): Promise<null> => {
    const bodyFormData = new FormData();
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        bodyFormData.append(key, value as any);
      }
    }
    if (draftImages) {
      for (const image of draftImages) {
        bodyFormData.append('draftImages', image);
      }
    }
    const { data } = await this.axios.post(`${draftWork}`, bodyFormData);
    return data;
  };

  addMoreDraftWork = async ({ draftImages, draftWorkId }: { draftImages: File[]; draftWorkId: string }): Promise<null> => {
    const bodyFormData = new FormData();

    if (draftImages) {
      for (const image of draftImages) {
        bodyFormData.append('draftImages', image);
      }
    }
    const { data } = await this.axios.put(`${draftWork}${draftWorkId}`, bodyFormData);
    return data;
  };

  addCaptionForPost = async ({ caption, draftWorkId }: { caption: string; draftWorkId: string }): Promise<null> => {
    const { data } = await this.axios.put(`${draftWork}add-caption/${draftWorkId}`, { caption });
    return data;
  };

  reviewDraftImage = async ({ ...params }: { draftId: string; file: string; status: WorkImageStatus; comment?: string }): Promise<null> => {
    const { data } = await this.axios.put(`${draftWork}review`, params);
    return data;
  };

  approveAllDraftWork = async ({ ...params }: { influencerId: string; applicantId: string }): Promise<null> => {
    const { data } = await this.axios.put(`${draftWork}approve-all`, params);
    return data;
  };

  getFinalWork = async ({ ...params }: DraftWorkQueryParams): Promise<FinalWorkRes> => {
    const { data } = await this.axios.get(`${finalWork}`, { params });
    return data;
  };

  createFinalWork = async ({ proveImages, ...params }: CreateFinalWorkParams): Promise<null> => {
    const bodyFormData = new FormData();
    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        bodyFormData.append(key, value as any);
      }
    }
    if (proveImages) {
      for (const image of proveImages) {
        bodyFormData.append('proveImages', image);
      }
    }
    const { data } = await this.axios.post(`${finalWork}`, bodyFormData);
    return data;
  };

  addMoreFinalWork = async ({ proveImages, finalWorkId }: { proveImages: File[]; finalWorkId: string }): Promise<null> => {
    const bodyFormData = new FormData();

    if (proveImages) {
      for (const image of proveImages) {
        bodyFormData.append('proveImages', image);
      }
    }
    const { data } = await this.axios.put(`${finalWork}${finalWorkId}`, bodyFormData);
    return data;
  };

  updateFinalWorkLink = async (params: { proveLink: string; finalWorkId: string }): Promise<null> => {
    const { data } = await this.axios.put(`${finalWork}link/${params.finalWorkId}`, { proveLink: params.proveLink });
    return data;
  };

  deleteFinalImage = async ({ ...params }: { finalId: string; file: string }): Promise<null> => {
    const { data } = await this.axios.delete(`${finalWork}`, { params });
    return data;
  };

  reviewFinalWork = async ({ ...params }: { finalId: string; status: WorkImageStatus; comment?: string }): Promise<null> => {
    const { data } = await this.axios.put(`${finalWork}review/${params.finalId}`, { status: params.status, comment: params.comment });
    return data;
  };

  approveAllFinalWork = async ({ ...params }: { influencerId: string; applicantId: string }): Promise<null> => {
    const { data } = await this.axios.put(`${finalWork}approve-all`, params);
    return data;
  };
}
