import { routes } from '@common/utils/routes';
import { storage, storageKey } from '@common/utils/storage';
import { ICONS } from '@components/SVG/Icon';
import { selectTotalUnreadMessage, selectUserType } from '@services/controllers/common/CommonSelector';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './UserDropdown.scss';
import { selectCurrentUser } from '@services/controllers/user/UserSelector';
import LocalizedLink from '@components/LocalizeLink/LocalizeLink';
import useLocalizeNavigate from '@common/hooks/useLocalizeNavigate';
import { AuthType } from '@common/enum';

export interface UserDropdownProps {
  children: React.ReactNode;
  isShowDropdown: boolean;
  isShowBadge?: boolean;
  handleClickChangeLanguage: () => void;
}

const UserDropdown: React.FunctionComponent<UserDropdownProps> = ({ children, isShowDropdown, isShowBadge, handleClickChangeLanguage }) => {
  const { t, i18n } = useTranslation();
  const navigate = useLocalizeNavigate();
  const totalUnreadMessage = useSelector(selectTotalUnreadMessage);
  const currentUser = useSelector(selectCurrentUser);
  const loginType = useSelector(selectUserType);

  const onLogout = (e: any) => {
    e.preventDefault();
    storage.removeToken();
    storage.removeStorageKey(storageKey.IS_NEW_USER);
    setTimeout(() => {
      navigate(routes.DEFAULT, null, true);
      window.location.reload();
    }, 1000);
  };

  const classes = classNames('user-dropdown__wrapper', {
    'user-dropdown__wrapper--show': isShowDropdown,
  });

  return (
    <div className="user-dropdown">
      <div className={classes}>
        {children}
        <div className="user-dropdown__content">
          <LocalizedLink to={routes.MY_PROFILE} className="user-dropdown__link">
            <div className="user-dropdown__item">
              <img src={ICONS.EDIT} alt="" className="user-dropdown__item-icon" />
              <div className="user-dropdown__item-content">{t('userDropdown.myProfile')}</div>
            </div>
          </LocalizedLink>
          <LocalizedLink className="user-dropdown__link" to={routes.MY_CAMPAIGN}>
            <div className="user-dropdown__item">
              <img src={ICONS.MEGAPHONE} alt="" className="user-dropdown__item-icon" />
              <div className="user-dropdown__item-content">{t('userDropdown.myCampaign')}</div>
              {currentUser?.numberOfCampaignNotView && currentUser.numberOfCampaignNotView > 0 ? (
                <div className="user-dropdown__item--red-dot" />
              ) : null}
            </div>
          </LocalizedLink>
          <LocalizedLink className="user-dropdown__link" to={routes.MESSAGE}>
            <div className="user-dropdown__item">
              <img src={ICONS.MESSAGE} alt="" className="user-dropdown__item-icon" />
              <div className="user-dropdown__item-content">{t('userDropdown.message')}</div>
              {totalUnreadMessage > 0 && (
                <div className="user-dropdown__item-badge">{totalUnreadMessage > 99 ? '99+' : totalUnreadMessage}</div>
              )}
            </div>
          </LocalizedLink>
          <LocalizedLink className="user-dropdown__link" to={routes.BALANCE}>
            <div className="user-dropdown__item">
              <img src={ICONS.BALANCE} alt="" className="user-dropdown__item-icon" />
              <div className="user-dropdown__item-content">{t('userDropdown.myBalance')}</div>
            </div>
          </LocalizedLink>
          <LocalizedLink className="user-dropdown__link" to={routes.ORDERS}>
            <div className="user-dropdown__item">
              <img src={ICONS.LIST_BOXES} alt="" className="user-dropdown__item-icon" />
              <div className="user-dropdown__item-content">{t('userDropdown.myOrders')}</div>
              {isShowBadge && <div className="user-dropdown__badge" />}
            </div>
          </LocalizedLink>
          {loginType === AuthType.BRANDS && (
            <LocalizedLink className="user-dropdown__link" to={routes.CALENDER}>
              <div className="user-dropdown__item">
                <img src={ICONS.CALENDAR} alt="" className="user-dropdown__item-icon" />
                <div className="user-dropdown__item-content">{t('userDropdown.myCalendar')}</div>
                {isShowBadge && <div className="user-dropdown__badge" />}
              </div>
            </LocalizedLink>
          )}

          <div className="user-dropdown__item" onClick={handleClickChangeLanguage}>
            <img className="user-dropdown__item-icon" src={i18n.language === 'en' ? ICONS.FLAG_EN : ICONS.FLAG_THAI} alt="language" />
            <div className="user-dropdown__item-content">{t('userDropdown.language')}</div>
          </div>
          <div className="user-dropdown__link" style={{ cursor: 'pointer' }} onClick={onLogout}>
            <div className="user-dropdown__item user-dropdown__item-logout">
              <img src={ICONS.LOG_OUT} alt="" className="user-dropdown__item-icon" />
              <div className="user-dropdown__item-content txt-logout">{t('userDropdown.logout')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDropdown;
